import './plugins';

import $ from 'jquery';


// Needed for hot module reloading in webpack
if (module.hot) {
  module.hot.accept();
}

KBF.registerInitCallback('always', function () {
  $(document).foundation();

  $(document).ready(function () {
    let timeout;
    $(window).on('scroll', function () {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function () {
        addBodyScrolled();
      }, 1);
    })
    $(window).trigger('scroll')
  })

  function addBodyScrolled() {
    const scrollTop = $('html').scrollTop();
    if (scrollTop > 50) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }

    if (scrollTop > 90) {
      $('body').addClass('nav-scrolled');
    } else {
      $('body').removeClass('nav-scrolled');
    }
  }

  // $(window).on('hashchange', function (e) {
  //   e.preventDefault();
  //   var hash = window.location.hash;
  //   var $target = $(hash);
  //   $('html, body').stop().animate({
  //     'scrollTop': $target.offset().top
  //   }, 900, 'swing');
  // })

  $('.mainnav a').on('click', function (e) {
    var is_root = location.pathname == "/";
    if (is_root) {
      e.preventDefault();
      e.stopPropagation();
      var href = $(this).attr('href');
      var hash = href.substring(href.indexOf("#") + 1);
      var $target = $('#' + hash);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 120
      }, 900, 'swing');
      // window.location.hash = hash;
    }
  });

  $('.menu--mob a').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var href = $(this).attr('href');
    var hash = href.substring(href.indexOf("#") + 1);
    var $target = $('#' + hash);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - 120
    }, 900, 'swing');
    $('.menu-toggle').trigger('click');
  });

  $('.menu-toggle').on('click', function () {
    if ($('html').scrollTop() < 50) {
      $('body').toggleClass('scrolled');
    }
  })

});
